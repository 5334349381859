<template>
  <nav class="navbar navbar-expand-lg ">
    <div class="container-fluid">
      <div class="navbar-minimize">
        <button class="btn btn-outline btn-fill btn-round btn-icon d-none d-lg-block" @click="minimizeSidebar">
          <i :class="$sidebar.isMinimized ? 'fa fa-ellipsis-v' : 'fa fa-bars'"></i>
        </button>
      </div>
      <a class="navbar-brand">{{this.$route.name}}</a>
      <button type="button"
              class="navbar-toggler navbar-toggler-right"
              :class="{toggled: $sidebar.showSidebar}"
              aria-expanded="false"
              @click="toggleSidebar">
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
        <span class="navbar-toggler-bar burger-lines"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end">
        <ul class="navbar-nav">
          <!--<drop-down>
            <i slot="title" class="nc-icon nc-planet"></i>
            <a class="dropdown-item" href="#">Control Assessments</a>
            <a class="dropdown-item" href="#">Vulnerability Assessments</a>-->
            <!--<li class="divider"></li>-->
            <!--<a class="dropdown-item" href="#">Risk Assessments</a>
          </drop-down>-->
          <!--<drop-down position="right">
            <template slot="title">
              <i class="nc-icon nc-bell-55"></i>
              <span class="notification">4</span>
              <span class="d-lg-none">Notification</span>
            </template>
            <a class="dropdown-item" href="#">DE Assessment - 26-01-2020</a>
            <a class="dropdown-item" href="#">DE Assessment - 28-01-2020</a>
            <a class="dropdown-item" href="#">DE Assessment - 26-02-2020</a>
            <a class="dropdown-item" href="#">DE Assessment - 30-01-2020</a>
          </drop-down>-->
          <drop-down position="right">
            <i slot="title" class="nc-icon nc-bullet-list-67"></i>

            <a class="dropdown-item" href="#">
              <i class="fa fa-tasks"></i> Tasks
            </a>
            <a class="dropdown-item" href="#">
              <i class="fa fa-question-circle"></i> Help Center
            </a>
            <div class="divider"></div>
            <a class="dropdown-item" href="/#/user">
              <i class="fa fa-user"></i> My Profile
            </a>
            <a class="dropdown-item" href="/#/lock">
              <i class="fa fa-key"></i> Password
            </a>
            <!--<a class="dropdown-item" href="/#/lock">
              <i class="nc-icon nc-lock-circle-open"></i> Lock Screen
            </a>-->
            <button @click="signOut" class="dropdown-item text-danger">
              <i class="fa fa-power-off"></i> Log out
            </button>

          </drop-down>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { Auth } from 'aws-amplify'

  export default {
    computed: {
      routeName () {
        const {name} = this.$route
        return this.capitalizeFirstLetter(name)
      }
    },
    data () {
      return {
        activeNotifications: false
      }
    },
    methods: {
      signOut() {
        let self = this;
      Auth.signOut()
        .then(data =>{
          //self.$store.state.signedIn = !!data;
          self.$store.state.signedIn = false;
          self.$store.state.user = null;
         self.$router.push(`/login`);
        } )
        .catch(err => console.log(err));
    },
      capitalizeFirstLetter (string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      },
      toggleNotificationDropDown () {
        this.activeNotifications = !this.activeNotifications
      },
      closeDropDown () {
        this.activeNotifications = false
      },
      toggleSidebar () {
        this.$sidebar.displaySidebar(!this.$sidebar.showSidebar)
      },
      hideSidebar () {
        this.$sidebar.displaySidebar(false)
      },
      minimizeSidebar () {
        this.$sidebar.toggleMinimize()
      }
    }
  }

</script>
<style>

</style>
