<template>
<div class="App">
  <main class="App__main">
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view></router-view>
      </transition>
    </main>
    </div>
</template>

<script>
import { AmplifyEventBus } from "aws-amplify-vue";
import { Auth } from "aws-amplify";
//import axios from "axios";

export default {
  name: "app",
  beforeCreate() {
    let self = this;
    AmplifyEventBus.$on("authState", (info) => {
      if (info === "signedIn") {
        Auth.currentAuthenticatedUser()
          .then((user) => {
            this.$store.state.signedIn = !!user;
            this.$store.state.user = user;
          })
          .catch(() => (this.$store.state.signedIn = false));
        this.$store.state.signedIn = true;
        self.$router.push("/");
      }
      if (info === "signedOut") {
        self.$router.push("/login");
      }
    });

    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.$store.state.signedIn = !!user;
        this.$store.state.user = user;
      })
      .catch(() => (this.$store.state.signedIn = false));
  }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition-duration: .3s !important;
  transition-property: opacity !important;
  transition-timing-function: ease !important;
}

.fade-enter,
.fade-leave-active {
  opacity: 0 !important;
}
</style>