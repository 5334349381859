<template>
  <nav aria-label="breadcrumb" role="navigation">
    <ol class="breadcrumb">
      <slot>

      </slot>
    </ol>
  </nav>
</template>
<script>
  export default {
    name: 'breadcrumb'
  }
</script>
<style>
.breadcrumb{
  background-color: #dce6f5 !important;
  padding: 0rem 0rem !important;
}
</style>
