class IndustryService {
  constructor(axios, apiUrl) {
    this.axios = axios;
    this.baseUrl = `${apiUrl}Industry`;
  }

  get(id) {
    let self = this;
    return self.axios.get(`${self.baseUrl}/${id}`);
  }

  getAll(header) {
    let self = this;
    return self.axios.get(`${self.baseUrl}`, header);
  }

  add(model) {
    let self = this;
    return self.axios.post(`${self.baseUrl}`, model);
  }

  update(model) {
    let self = this;
    return self.axios.put(`${self.baseUrl}/${model.id}`, model);
  }

  remove(id) {
    let self = this;
    return self.axios.delete(`${self.baseUrl}/${id}`);
  }
}

export default IndustryService;
