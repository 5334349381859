<template>
  <div class="wrapper" :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>
    <side-bar>
      <!--<user-menu></user-menu>-->
      <mobile-menu></mobile-menu>
      <template slot-scope="props" slot="links">
        <!--<sidebar-item :link="{name: 'Dashboard', icon: 'fa fa-chart-pie', path: '/overview'}">-->
        <template v-if="userRoles.includes('SecurityStaff') || userRoles.includes('Admin') || userRoles.includes('ReportUser')">
        <sidebar-item :link="{name: 'Dashboards', icon: 'fa fa-chart-pie'}">
          <sidebar-item
            :link="{name: 'Control Dashboards', icon: 'fa fa-shield-alt', path: '/controlAssessmentDashboards'}"
          ></sidebar-item>
        </sidebar-item>
        </template>
        <!--<sidebar-item :link="{name: 'Assessments', icon: 'fa fa-diagnoses', path: '/assessments'}"></sidebar-item>-->
         <template v-if="userRoles.includes('SecurityStaff') || userRoles.includes('Admin')">
        <sidebar-item :link="{name: 'Control Assmts', icon: 'fa fa-shield-alt'}">
          <sidebar-item
            :link="{name: 'Design Effectiveness', icon: 'fa fa-pencil-ruler', path: '/designeffectiveness'}"
          ></sidebar-item>
          <sidebar-item
            :link="{name: 'Operational Effectiveness', icon: 'fa fa-chart-bar', path: '/operationaleffectiveness'}"
          ></sidebar-item>
        </sidebar-item>
         </template>
        <!--<sidebar-item :link="{name: 'Assess Vulnerabilities', icon: 'fa fa-lock-open', path: '/vulnerabilityassessments'}">
        </sidebar-item>
        <sidebar-item :link="{name: 'Risk Management', icon: 'fa fa-dice'}">
          <sidebar-item :link="{name: 'Risk Definitions', path: '/forms/regular'}"></sidebar-item>
          <sidebar-item :link="{name: 'Scenarios', path: '/forms/extended'}"></sidebar-item>
          <sidebar-item :link="{name: 'Assess Risks', path: '/forms/validation'}"></sidebar-item>
        </sidebar-item>-->
         <template v-if="userRoles.includes('Admin')">
        <sidebar-item :link="{name: 'Settings', icon: 'fa fa-cogs'}">
          <sidebar-item :link="{name: 'Organisations', icon: 'fa fa-city', path: '/organisations'}"></sidebar-item>
          <sidebar-item
            :link="{name: 'Control Frameworks', icon: 'fa fa-lock', path: '/controlframeworks'}"
          ></sidebar-item>
          <!--<sidebar-item :link="{name: 'Surveys', icon: 'fa fa-tasks', path: '/surveys'}"></sidebar-item>-->
          <sidebar-item
            :link="{name: 'Threat Communities', icon: 'fa fa-user-secret', path: '/threatcommunities'}"
          ></sidebar-item>
          <sidebar-item
            :link="{name: 'Loss Categories', icon: 'fa fa-exclamation-triangle', path: '/losses'}"
          ></sidebar-item>
          <sidebar-item
            :link="{name: 'Qualitative Settings', icon: 'fa fa-traffic-light', path: '/appetites'}"
          ></sidebar-item>
          <sidebar-item :link="{name: 'Countries', icon: 'fa fa-globe', path: '/countries'}"></sidebar-item>
          <sidebar-item :link="{name: 'Industries', icon: 'fa fa-industry', path: '/industries'}"></sidebar-item>
           <sidebar-item
            :link="{name: 'User Management', icon: 'fa fa-user', path: '/users'}"
          ></sidebar-item>
        </sidebar-item>
        </template>
        <!-- <sidebar-item :link="{name: 'Percentile', icon: 'fa fa-percent', path: '/chosenpercentiles'}">
        </sidebar-item>
        <sidebar-item :link="{name: 'Industry', icon: 'fa fa-industry', path: '/industries'}">
        </sidebar-item>
        <sidebar-item :link="{name: 'Organisation', icon: 'fa fa-bank', path: '/organisations'}">
        </sidebar-item>
        <sidebar-item :link="{name: 'Country', icon: 'fa fa-globe', path: '/countries'}">
        </sidebar-item>
          <sidebar-item :link="{name: 'Components', icon: 'nc-icon nc-app'}">
          <sidebar-item :link="{name: 'Buttons', path: '/components/buttons'}"></sidebar-item>
          <sidebar-item :link="{name: 'Grid System', path: '/components/grid-system'}"></sidebar-item>
          <sidebar-item :link="{name: 'Panels', path: '/components/panels'}"></sidebar-item>
          <sidebar-item :link="{name: 'Sweet Alert', path: '/components/sweet-alert'}"></sidebar-item>
          <sidebar-item :link="{name: 'Notifications', path: '/components/notifications'}"></sidebar-item>
          <sidebar-item :link="{name: 'Icons', path: '/components/icons'}"></sidebar-item>
          <sidebar-item :link="{name: 'Typography', path: '/components/typography'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'Forms', icon: 'nc-icon nc-notes'}">
          <sidebar-item :link="{name: 'Regular Forms', path: '/forms/regular'}"></sidebar-item>
          <sidebar-item :link="{name: 'Extended Forms', path: '/forms/extended'}"></sidebar-item>
          <sidebar-item :link="{name: 'Validation Forms', path: '/forms/validation'}"></sidebar-item>
          <sidebar-item :link="{name: 'Wizard', path: '/forms/wizard'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'Tables', icon: 'nc-icon nc-paper-2'}">
          <sidebar-item :link="{name: 'Regular Tables', path: '/table-list/regular'}"></sidebar-item>
          <sidebar-item :link="{name: 'Extended Tables', path: '/table-list/extended'}"></sidebar-item>
          <sidebar-item :link="{name: 'Paginated Tables', path: '/table-list/paginated'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'Maps', icon: 'nc-icon nc-pin-3'}">
          <sidebar-item :link="{name: 'Google Maps', path: '/maps/google'}"></sidebar-item>
          <sidebar-item :link="{name: 'Full Screen Maps', path: '/maps/full-screen'}"></sidebar-item>
          <sidebar-item :link="{name: 'Vector Maps', path: '/maps/vector-map'}"></sidebar-item>
        </sidebar-item>
        <sidebar-item :link="{name: 'Charts', icon: 'nc-icon nc-chart-bar-32', path: '/admin/charts'}"></sidebar-item>
        <sidebar-item :link="{name: 'Calendar', icon: 'nc-icon nc-single-copy-04', path: '/admin/calendar'}"></sidebar-item>
        <sidebar-item :link="{name: 'Pages', icon: 'nc-icon nc-puzzle-10'}">
          <sidebar-item :link="{name: 'User Page', path: '/pages/user'}"></sidebar-item>
          <sidebar-item :link="{name: 'Login Page', path: '/login'}"></sidebar-item>
          <sidebar-item :link="{name: 'Register', path: '/register'}"></sidebar-item>
          <sidebar-item :link="{name: 'Lock Screen Page', path: '/lock'}"></sidebar-item>
        </sidebar-item>-->
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar"></dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import Vue from "vue";
//import UserMenu from './Extra/UserMenu.vue'
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
    //UserMenu
  },
  data() {
    return {
      user: null,
      userRoles: []
    };
  },
  created() {
    let self = this;

    Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then(data => {
      if (data && data.signInUserSession) {
        self.user = data;
        //self.user = self.$store.state.user;
        self.userRoles =
          self.user.signInUserSession.accessToken.payload["cognito:groups"];
      }
    }) .catch(err => console.log(err));
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>
<style lang="scss">
.vue-notifyjs.notifications {
  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }
  .list-item {
    display: inline-block;
    margin-right: 10px;
  }
  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }
  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }

  .list-enter {
    opacity: 0;
    transform: scale(1.1);
  }
  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
}
</style>
