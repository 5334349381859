import DashboardLayout from 'src/pages/Dashboard/Layout/DashboardLayout.vue';
// GeneralViews
import NotFound from 'src/pages/GeneralViews/NotFoundPage.vue';
// Dashboard pages
import Overview from 'src/pages/Dashboard/Dashboard/Overview.vue';
import Stats from 'src/pages/Dashboard/Dashboard/Stats.vue';
import DesignEffectivenessDashboard from 'src/pages/Dashboard/Dashboard/ControlAssessments/DesignEffectivenessDashboard.vue';
import ControlAssessmentDashboardOrganisation from 'src/pages/Dashboard/Dashboard/ControlAssessments/ControlAssessmentOrganisation.vue';
import DesignEffectivenessAssessmentDetail from 'src/pages/Dashboard/Dashboard/ControlAssessments/DesignEffectivenessAssessmentDetail.vue';

// Pages
import User from 'src/pages/Dashboard/Pages/UserProfile.vue';
import TimeLine from 'src/pages/Dashboard/Pages/TimeLinePage.vue';
import Login from 'src/pages/Dashboard/Pages/Login.vue';
import Register from 'src/pages/Dashboard/Pages/Register.vue';
import Lock from 'src/pages/Dashboard/Pages/Lock.vue';

// Components pages
import Buttons from 'src/pages/Dashboard/Components/Buttons.vue';
import GridSystem from 'src/pages/Dashboard/Components/GridSystem.vue';
import Panels from 'src/pages/Dashboard/Components/Panels.vue';
import Notifications from 'src/pages/Dashboard/Components/Notifications.vue';
import Icons from 'src/pages/Dashboard/Components/Icons.vue';
import Typography from 'src/pages/Dashboard/Components/Typography.vue';

import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

const SweetAlert = () => import('src/pages/Dashboard/Components/SweetAlert.vue');
// Forms pages
const RegularForms = () => import('src/pages/Dashboard/Forms/RegularForms.vue');
const ExtendedForms = () => import('src/pages/Dashboard/Forms/ExtendedForms.vue');
const ValidationForms = () => import('src/pages/Dashboard/Forms/ValidationForms.vue');
const Wizard = () => import('src/pages/Dashboard/Forms/Wizard.vue');

// TableList pages
const RegularTables = () => import('src/pages/Dashboard/Tables/RegularTables.vue');
const ExtendedTables = () => import('src/pages/Dashboard/Tables/ExtendedTables.vue');
const PaginatedTables = () => import('src/pages/Dashboard/Tables/PaginatedTables.vue');
// Maps pages
const GoogleMaps = () => import('src/pages/Dashboard/Maps/GoogleMaps.vue');
const FullScreenMap = () => import('src/pages/Dashboard/Maps/FullScreenMap.vue');
const VectorMaps = () => import('src/pages/Dashboard/Maps/VectorMapsPage.vue');

// Calendar
const Calendar = () => import('src/pages/Dashboard/Calendar/CalendarRoute.vue');

// Appetite pages
const AppetiteMain = () => import('src/pages/Dashboard/Appetites/AppetiteMain.vue');
const AppetiteEdit = () => import('src/pages/Dashboard/Appetites/AppetiteEdit.vue');

// Industry pages
const IndustryMain = () => import('src/pages/Dashboard/Industries/IndustryMain.vue');
const IndustryEdit = () => import('src/pages/Dashboard/Industries/IndustryEdit.vue');

// Country pages
const CountryMain = () => import('src/pages/Dashboard/Countries/CountryMain.vue');
const CountryEdit = () => import('src/pages/Dashboard/Countries/CountryEdit.vue');

// Organisation pages
const OrganisationMain = () => import('src/pages/Dashboard/Organisations/OrganisationMain.vue');
const OrganisationEdit = () => import('src/pages/Dashboard/Organisations/OrganisationEdit.vue');

// ThreatCommunity pages
const ThreatCommunityMain = () => import('src/pages/Dashboard/ThreatCommunities/ThreatCommunityMain.vue');
const ThreatCommunityEdit = () => import('src/pages/Dashboard/ThreatCommunities/ThreatCommunityEdit.vue');

// Loss pages
const LossMain = () => import('src/pages/Dashboard/Losses/LossMain.vue');
const LossEdit = () => import('src/pages/Dashboard/Losses/LossEdit.vue');

// Control Framework pages
const ControlFrameworkMain = () => import('src/pages/Dashboard/ControlFrameworks/ControlFrameworkMain.vue');
const ControlFrameworkEdit = () => import('src/pages/Dashboard/ControlFrameworks/ControlFrameworkEdit.vue');

// Control Framework Category pages
const ControlFrameworkCategoryMain = () => import('src/pages/Dashboard/ControlFrameworks/ControlFrameworkCategories/ControlFrameworkCategoryMain.vue');
const ControlFrameworkCategoryEdit = () => import('src/pages/Dashboard/ControlFrameworks/ControlFrameworkCategories/ControlFrameworkCategoryEdit.vue');

// Survey pages
const SurveyMain = () => import('src/pages/Dashboard/ControlFrameworks/ControlFrameworkCategories/Surveys/SurveyMain.vue');
const SurveyEdit = () => import('src/pages/Dashboard/ControlFrameworks/ControlFrameworkCategories/Surveys/SurveyEdit.vue');

// OEMetric pages
const OEMetricMain = () => import('src/pages/Dashboard/ControlFrameworks/ControlFrameworkCategories/OEMetrics/OEMetricMain.vue');
const OEMetricEdit = () => import('src/pages/Dashboard/ControlFrameworks/ControlFrameworkCategories/OEMetrics/OEMetricEdit.vue');

// Design Effectiveness pages
const DesignEffectivenessOrganisation = () => import('src/pages/Dashboard/DesignEffectiveness/DesignEffectivenessOrganisation.vue');
const DesignEffectivenessMain = () => import('src/pages/Dashboard/DesignEffectiveness/DesignEffectivenessMain.vue');
const DesignEffectivenessEdit = () => import('src/pages/Dashboard/DesignEffectiveness/DesignEffectivenessEdit.vue');
const DesignEffectivenessNew = () => import('src/pages/Dashboard/DesignEffectiveness/DesignEffectivenessNew.vue');
const DesignEffectivenessDetail = () => import('src/pages/Dashboard/DesignEffectiveness/DesignEffectivenessDetail.vue');
const DesignEffectivenessAssessment = () => import('src/pages/Dashboard/DesignEffectiveness/DesignEffectivenessAssessment.vue');

// Operational Effectiveness pages
const OperationalEffectivenessOrganisation = () => import('src/pages/Dashboard/OperationalEffectiveness/OperationalEffectivenessOrganisation.vue');
const OperationalEffectivenessMain = () => import('src/pages/Dashboard/OperationalEffectiveness/OperationalEffectivenessMain.vue');
const OperationalEffectivenessEdit = () => import('src/pages/Dashboard/OperationalEffectiveness/OperationalEffectivenessEdit.vue');
const OperationalEffectivenessNew = () => import('src/pages/Dashboard/OperationalEffectiveness/OperationalEffectivenessNew.vue');
const OperationalEffectivenessDetail = () => import('src/pages/Dashboard/OperationalEffectiveness/OperationalEffectivenessDetail.vue');
const OperationalEffectivenessAssessment = () => import('src/pages/Dashboard/OperationalEffectiveness/OperationalEffectivenessAssessment.vue');

// User pages
const UserMain = () => import('src/pages/Dashboard/Users/UserMain.vue');
const UserEdit = () => import('src/pages/Dashboard/Users/UserEdit.vue');
const ChangePassword = () => import('src/pages/Dashboard/Users/ChangePassword.vue');


// Charts
const Charts = () => import('src/pages/Dashboard/Charts.vue');

let componentsMenu = {
  path: '/components',
  component: DashboardLayout,
  redirect: '/components/buttons',
  children: [{
      path: 'buttons',
      name: 'Buttons',
      component: Buttons
    },
    {
      path: 'grid-system',
      name: 'Grid System',
      component: GridSystem
    },
    {
      path: 'panels',
      name: 'Panels',
      component: Panels
    },
    {
      path: 'sweet-alert',
      name: 'Sweet Alert',
      component: SweetAlert
    },
    {
      path: 'notifications',
      name: 'Notifications',
      component: Notifications
    },
    {
      path: 'icons',
      name: 'Icons',
      component: Icons
    },
    {
      path: 'typography',
      name: 'Typography',
      component: Typography
    }

  ]
};
let formsMenu = {
  path: '/forms',
  component: DashboardLayout,
  redirect: '/forms/regular',
  children: [{
      path: 'regular',
      name: 'Regular Forms',
      component: RegularForms
    },
    {
      path: 'extended',
      name: 'Extended Forms',
      component: ExtendedForms
    },
    {
      path: 'validation',
      name: 'Validation Forms',
      component: ValidationForms
    },
    {
      path: 'wizard',
      name: 'Wizard',
      component: Wizard
    }
  ]
};

let tablesMenu = {
  path: '/table-list',
  component: DashboardLayout,
  redirect: '/table-list/regular',
  children: [{
      path: 'regular',
      name: 'Regular Tables',
      component: RegularTables
    },
    {
      path: 'extended',
      name: 'Extended Tables',
      component: ExtendedTables
    },
    {
      path: 'paginated',
      name: 'Paginated Tables',
      component: PaginatedTables
    }
  ]
};

let appetiteMenu = {
  path: '/appetites',
  component: DashboardLayout,
  redirect: '/appetites',
  meta: {
    requiresAuth: true
  },
  children: [{
      path: '/',
      name: 'Qualitative Settings',
      component: AppetiteMain,
      meta: {
        authorize: ['Admin']
      }
    },
    {
      path: ':id/edit',
      name: 'Edit Qualitative Settings',
      component: AppetiteEdit,
      meta: {
        authorize: ['Admin']
      }
    },
    {
      path: 'new',
      name: 'Add Qualitative Settings',
      component: AppetiteEdit,
      meta: {
        authorize: ['Admin']
      }
    }
  ]
};

let industryMenu = {
  path: '/industries',
  component: DashboardLayout,
  redirect: '/industries',
  meta: {
    requiresAuth: true
  },
  children: [{
      path: '/',
      name: 'Industries',
      component: IndustryMain,
      meta: {
        authorize: ['Admin']
      }
    },
    {
      path: ':id/edit',
      name: 'Edit Industry',
      component: IndustryEdit,
      meta: {
        authorize: ['Admin']
      }
    },
    {
      path: 'new',
      name: 'Add Industry',
      component: IndustryEdit,
      meta: {
        authorize: ['Admin']
      }
    }
  ]
};

let countryMenu = {
  path: '/countries',
  component: DashboardLayout,
  redirect: '/countries',
  meta: {
    requiresAuth: true
  },
  children: [{
      path: '/',
      name: 'Countries',
      component: CountryMain,
      meta: {
        authorize: ['Admin']
      },
    },
    {
      path: ':id/edit',
      name: 'Edit Country',
      component: CountryEdit,
      meta: {
        authorize: ['Admin']
      }
    },
    {
      path: 'new',
      name: 'Add Country',
      component: CountryEdit,
      meta: {
        authorize: ['Admin']
      }
    }
  ]
};

let organisationMenu = {
  path: '/organisations',
  component: DashboardLayout,
  redirect: '/organisations',
  meta: {
    requiresAuth: true
  },
  children: [{
      path: '/',
      name: 'Organisations',
      component: OrganisationMain,
      meta: {
        authorize: ['Admin']
      }
    },
    {
      path: ':id/edit',
      name: 'Edit Organisation',
      component: OrganisationEdit,
      meta: {
        authorize: ['Admin']
      }
    },
    {
      path: 'new',
      name: 'Add Organisation',
      component: OrganisationEdit,
      meta: {
        authorize: ['Admin']
      }
    }
  ]
};

let designEffectivenessMenu = {
  path: '/designEffectiveness',
  component: DashboardLayout,
  redirect: '/designEffectiveness',
  meta: {
    requiresAuth: true
  },
  children: [{
      path: '/',
      name: 'Design Effectiveness - Select Entity',
      component: DesignEffectivenessOrganisation,
      meta: {
        authorize: ['Admin', 'SecurityStaff']
      }
    },
    {
      path: ':organisationId/deassessments',
      name: 'Design Effectiveness Assessments',
      component: DesignEffectivenessMain,
      meta: {
        authorize: ['Admin', 'SecurityStaff']
      }
    },
    {
    path: ':organisationId/deassessments/:id/edit',
    name: 'Edit Design Effectiveness Assessment',
    component: DesignEffectivenessEdit,
    meta: {
      authorize: ['Admin', 'SecurityStaff']
    }
    },
    {
      path: ':organisationId/deassessments/:id/detail',
      name: 'Design Effectiveness Detail',
      component: DesignEffectivenessDetail,
      meta: {
        authorize: ['Admin', 'SecurityStaff']
      }
    },
    {
      path: ':organisationId/deassessments/:id/detail/:controlAssessmentSurveyId/assessment',
      name: 'Design Effectiveness Assessment',
      component: DesignEffectivenessAssessment,
      meta: {
        authorize: ['Admin', 'SecurityStaff']
      }
    },
    {
      path: ':organisationId/deassessments/new',
      name: 'New Design Effectiveness Assessment',
      component: DesignEffectivenessNew,
      meta: {
        authorize: ['Admin', 'SecurityStaff']
      }
    }

  ]
};

let operationalEffectivenessMenu = {
  path: '/operationalEffectiveness',
  component: DashboardLayout,
  redirect: '/operationalEffectiveness',
  meta: {
    requiresAuth: true
  },
  children: [{
      path: '/',
      name: 'Operational Effectiveness - Select Entity',
      component: OperationalEffectivenessOrganisation,
      meta: {
        authorize: ['Admin', 'SecurityStaff']
      }
    },
    {
      path: ':organisationId/oeassessments',
      name: 'Operational Effectiveness Assessments',
      component: OperationalEffectivenessMain,
      meta: {
        authorize: ['Admin', 'SecurityStaff']
      }
    },
    {
    path: ':organisationId/oeassessments/:id/edit',
    name: 'Edit Operational Effectiveness Assessment',
    component: OperationalEffectivenessEdit,
    meta: {
      authorize: ['Admin', 'SecurityStaff']
    }
    },
    {
      path: ':organisationId/oeassessments/:id/detail',
      name: 'Operational Effectiveness Detail',
      component: OperationalEffectivenessDetail,
      meta: {
        authorize: ['Admin', 'SecurityStaff']
      }
    },
    {
      path: ':organisationId/oeassessments/:id/detail/:controlAssessmentCategoryId/assessment',
      name: 'Operational Effectiveness Assessment',
      component: OperationalEffectivenessAssessment,
      meta: {
        authorize: ['Admin', 'SecurityStaff']
      }
    },
    {
      path: ':organisationId/oeassessments/new',
      name: 'New Operational Effectiveness Assessment',
      component: OperationalEffectivenessNew,
      meta: {
        authorize: ['Admin', 'SecurityStaff']
      }
    }

  ]
};


let controlAssessmentMenu = {
  path: '/controlAssessmentDashboards',
  component: DashboardLayout,
  redirect: '/controlAssessmentdashboards',
  meta: {
    requiresAuth: true
  },
  children: [{
      path: '/',
      name: 'Control Assessment Dashboards',
      component: ControlAssessmentDashboardOrganisation,
      meta: {
        authorize: ['Admin', 'SecurityStaff', 'ReportUser']
      }
    },
    {
      path: ':organisationId/designeffectivenessdashboard/:controlassessmentId',
      name: 'Design Effectiveness Dashboard',
      component: DesignEffectivenessDashboard,
      meta: {
        authorize: ['Admin', 'SecurityStaff', 'ReportUser']
      }
    },
    {
      path: ':organisationId/designeffectivenessdashboard/:controlassessmentId/:controlAssessmentSurveyId',
      name: 'Design Effectiveness Dashboard Detail',
      component: DesignEffectivenessAssessmentDetail,
      meta: {
        authorize: ['Admin', 'SecurityStaff', 'ReportUser']
      }
    }

  ]
};


let threatCommunityMenu = {
  path: '/threatcommunities',
  component: DashboardLayout,
  redirect: '/threatcommunities',
  meta: {
    requiresAuth: true
  },
  children: [{
      path: '/',
      name: 'Threat Communities',
      component: ThreatCommunityMain,
      meta: {
        authorize: ['Admin']
      }
    },
    {
      path: ':id/edit',
      name: 'Edit Threat Community',
      component: ThreatCommunityEdit,
      meta: {
        authorize: ['Admin']
      }
    },
    {
      path: 'new',
      name: 'Add Threat Community',
      component: ThreatCommunityEdit,
      meta: {
        authorize: ['Admin']
      }
    }
  ]
};

let lossMenu = {
  path: '/losses',
  component: DashboardLayout,
  redirect: '/losses',
  meta: {
    requiresAuth: true
  },
  children: [{
      path: '/',
      name: 'Loss Categories',
      component: LossMain,
      meta: {
        authorize: ['Admin']
      }
    },
    {
      path: ':id/edit',
      name: 'Edit Loss Category',
      component: LossEdit,
      meta: {
        authorize: ['Admin']
      }
    },
    {
      path: 'new',
      name: 'Add Loss Category',
      component: LossEdit,
      meta: {
        authorize: ['Admin']
      }
    }
  ]
};

let controlFrameworkMenu = {
  path: '/controlframeworks',
  component: DashboardLayout,
  redirect: '/controlframeworks',
  meta: {
    requiresAuth: true
  },
  children: [{
      path: '/',
      name: 'Control Frameworks',
      component: ControlFrameworkMain,
      meta: {
        authorize: ['Admin']
      }
    },
    {
      path: ':id/edit',
      name: 'Edit Control Framework',
      component: ControlFrameworkEdit,
      meta: {
        authorize: ['Admin']
      }
    },
    {
      path: 'new',
      name: 'Add Control Framework',
      component: ControlFrameworkEdit,
      meta: {
        authorize: ['Admin']
      }
    }
  ]
};

let controlFrameworkCategoryMenu = {
  path: '/controlframeworks',
  component: DashboardLayout,
  redirect: '/controlframeworks',
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: ':controlFrameworkId/controlframeworkcategories/:id/edit',
      name: 'Edit Control Framework Category',
      component: ControlFrameworkCategoryEdit,
      meta: {
        authorize: ['Admin']
      }
    },
    {
      path: ':controlFrameworkId/controlframeworkcategories/new',
      name: 'Add Control Framework Category',
      component: ControlFrameworkCategoryEdit,
      meta: {
        authorize: ['Admin']
      }
    }
  ]
};

let surveyMenu = {
  path: '/controlframeworks',
  component: DashboardLayout,
  redirect: '/controlframeworks',
  meta: {
    requiresAuth: true
  },
  children: [{
      path: ':controlFrameworkId/controlframeworkcategories/:controlFrameworkCategoryId/surveys',
      name: 'Design Effectiveness Surveys',
      component: SurveyMain,
      meta: {
        authorize: ['Admin']
      }
    },
    {
      path: ':controlFrameworkId/controlframeworkcategories/:controlFrameworkCategoryId/surveys/:id/edit',
      name: 'Edit Design Effectiveness Survey',
      component: SurveyEdit,
      meta: {
        authorize: ['Admin']
      }
    },
    {
      path: ':controlFrameworkId/controlframeworkcategories/:controlFrameworkCategoryId/surveys/new',
      name: 'Add Design Effectiveness Survey',
      component: SurveyEdit,
      meta: {
        authorize: ['Admin']
      }
    }
  ]
};
let oEMetricMenu = {
  path: '/controlframeworks',
  component: DashboardLayout,
  redirect: '/controlframeworks',
  meta: {
    requiresAuth: true
  },
  children: [{
      path: ':controlFrameworkId/controlframeworkcategories/:controlFrameworkCategoryId/oemetrics',
      name: 'Operational Effectiveness Metrics',
      component: OEMetricMain,
      meta: {
        authorize: ['Admin']
      }
    },
    {
      path: ':controlFrameworkId/controlframeworkcategories/:controlFrameworkCategoryId/oemetrics/:id/edit',
      name: 'Edit Operational Effectiveness Metric',
      component: OEMetricEdit,
      meta: {
        authorize: ['Admin']
      }
    },
    {
      path: ':controlFrameworkId/controlframeworkcategories/:controlFrameworkCategoryId/oemetrics/new',
      name: 'Add Operational Effectiveness Metric',
      component: OEMetricEdit,
      meta: {
        authorize: ['Admin']
      }
    }
  ]
};

let userMenu = {
  path: '/users',
  component: DashboardLayout,
  redirect: '/users',
  meta: {
    requiresAuth: true
  },
  children: [{
      path: '/',
      name: 'Users',
      component: UserMain,
      meta: {
        authorize: ['Admin']
      }
    },
    {
      path: ':id/edit',
      name: 'Edit User',
      component: UserEdit,
      meta: {
        authorize: ['Admin', 'SecurityStaff', 'ReportUser']
      }
    },
    {
      path: 'new',
      name: 'Add User',
      component: UserEdit,
      meta: {
        authorize: ['Admin']
      }
    },
    {
      path: ':id/changepassword',
      name: 'Change Password',
      component: ChangePassword,
      meta: {
        authorize: ['Admin', 'SecurityStaff', 'ReportUser']
      }
    }
  ]
};

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  redirect: '/maps/google',
  children: [{
      path: 'google',
      name: 'Google Maps',
      component: GoogleMaps
    },
    {
      path: 'full-screen',
      name: 'Full Screen Map',
      component: FullScreenMap
    },
    {
      path: 'vector-map',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
};

let pagesMenu = {
  path: '/pages',
  component: DashboardLayout,
  redirect: '/user',
  children: [{
      path: '/user',
      name: 'User Page',
      component: User
    },
    {
      path: 'timeline',
      name: 'Timeline Page',
      component: TimeLine
    }
  ]
};

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
};

let registerPage = {
  path: '/register',
  name: 'Register',
  component: Register
};

let lockPage = {
  path: '/lock',
  name: 'Lock',
  component: Lock
};

const routes = [{
    path: '/',
    redirect: '/overview'
  },
  appetiteMenu,
  industryMenu,
  countryMenu,
  componentsMenu,
  organisationMenu,
  threatCommunityMenu,
  lossMenu,
  controlFrameworkMenu,
  controlFrameworkCategoryMenu,
  designEffectivenessMenu,
  operationalEffectivenessMenu,
  controlAssessmentMenu,
  surveyMenu,
  oEMetricMenu,
  userMenu,
  formsMenu,
  tablesMenu,
  mapsMenu,
  pagesMenu,
  loginPage,
  registerPage,
  lockPage,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/overview',
    meta: {
      requiresAuth: true
    },
    children: [{
        path: 'overview',
        name: 'Overview',
        component: Overview
      },
      {
        path: 'stats',
        name: 'Stats',
        component: Stats
      },
      {
        path: 'calendar',
        name: 'Calendar',
        component: Calendar
      },
      {
        path: 'charts',
        name: 'Charts',
        component: Charts
      }
    ]
  },
  {
    path: '*',
    component: NotFound
  }
];

export default routes;
