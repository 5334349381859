<template>
  <div class="row">
    <div class="col-md-12">
      <div class="col-12">
        <card v-if="loaded">
          <div class="text-right">
            <button
              type="button"
              @click="saveSurveyToPdf"
              class="btn btn-fill btn-info btn-wd"
            >
              <span class="btn-label">
                <i class="fa fa-file-pdf"></i>
              </span>
              Download
            </button>
            <button
              type="button"
              @click="backButtonClick"
              class="btn btn-fill btn-info btn-wd"
            >
              <span class="btn-label">
                <i class="fa fa-arrow-circle-left"></i>
              </span>
              Back
            </button>
            <checkbox v-model="checkboxValue">Download Editable PDF</checkbox>
          </div>
          <survey :survey="survey"></survey>
        </card>
      </div>
    </div>
  </div>
</template>


<script>
import * as SurveyVue from "survey-vue";
import * as SurveyPDF from "survey-pdf";
import { Checkbox } from "src/components/index";
import "bootstrap/dist/css/bootstrap.css";

var Survey = SurveyVue.Survey;
Survey.cssType = "bootstrap";

SurveyVue.Serializer.addProperty("question", "tag:number");
SurveyVue.Serializer.addProperty("question", "weight:number");

export default {
  components: {
    Checkbox,
  },
  data() {
    return {
      survey: new SurveyVue.Model(JSON.parse("{}")),
      surveyJSON: JSON.parse("{}"),
      checkboxValue: false,
      model: {
        id: 0,
        answers: JSON.parse("{}"),
        percentageCompleted: 0,
        isComplete: "",
        score: 0,
      },
      loaded: false
    };
  },

  created() {
    let self = this;
    self.get(self.$route.params.controlAssessmentSurveyId);
  },

  methods: {
    get(controlAssessmentSurveyId) {
      let self = this;
      self.$store.state.services.dEControlAssessmentSurveyService
        .get(controlAssessmentSurveyId)
        .then((r) => {
          self.model.id = r.data.id;
          self.survey = new SurveyVue.Model(JSON.parse(r.data.survey));
          self.surveyJSON = r.data.survey;
          self.model.answers = JSON.parse(r.data.answers);
          self.model.isComplete = r.data.isComplete;
          self.model.percentageCompleted = r.data.percentageCompleted;

          //Read only survey
          self.survey.data = self.model.answers;
          self.survey.mode = "display";
          self.survey.questionsOnPageMode = "singlePage";
          self.survey.showNavigationButtons = "none";
          self.survey.showTimerPanel = "none";
          self.survey.showProgressBar = "none";
          self.survey.onAfterRenderQuestion.add((survey, options) => {
            var isCorrect = options.question.isAnswerCorrect();
            var header = options.htmlElement.querySelector("h5");
            if (isCorrect) {
              header.style.color = "green";
            } else {
              header.style.color = "red";
            }
          });
          self.loaded = true;
        })
        .catch((err) => console.log(err));
    },
    backButtonClick() {
      let self = this;
      self.$router.push(
        `/controlAssessmentDashboards/${self.$route.params.organisationId}/designeffectivenessdashboard/${self.$route.params.controlassessmentId}`
      );
    },
    saveSurveyToPdf(filename, surveyModel, pdfWidth, pdfHeight) {
      let self = this;
      var pdfWidth = self.survey.pdfWidth || 210;
      var pdfHeight = self.survey.pdfHeight || 297;
      /*var options = {
        fontSize: 14,
        fontName: 'times',
        fontStyle: 'normal',
        margins: {
            left: 10,
            right: 10,
            top: 10,
            bot: 10
        },
        format: [pdfWidth, pdfHeight]
    };*/
      var surveyPDF = new SurveyPDF.SurveyPDF(self.surveyJSON);//, options);
      surveyPDF.data = self.survey.data;
      surveyPDF.questionsOnPageMode = "singlePage";
      if (self.checkboxValue == false) {
        surveyPDF.mode = "display";
      }
      //Should pay for licence before going live
      surveyPDF.haveCommercialLicense = true;
      surveyPDF.save("DE_Assessment.pdf");
      self.notifyVue ('bottom', 'right', 'success')
    },

    notifyVue (verticalAlign, horizontalAlign, messageType) {
        this.$notify(
          {
            component: {
              template: `<span>Downloaded Assessment</span>`
            },
            icon: 'fa fa-check',
            horizontalAlign: horizontalAlign,
            verticalAlign: verticalAlign,
            type: messageType
          })
      }
  },
};
</script>
<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.sv_q_m_cell_text {
  font-weight: normal !important;
  font-size: 13px !important;
  background-color: white !important;
}
.sv_q_m_cell_selected {
  background-color: #d5f5df !important;
}

.sv_q_matrix td {
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border: 3px solid black;
  font-size: 13px;
  widows: 16.66%;
  background-color: #f0f0f0;
}
.sv_q_matrix {
  table-layout: fixed;
  width: 100%;
}
.sv_prev_btn,
.sv_preview_btn,
.sv_next_btn,
.sv_edit_btn,
.sv_start_btn,
.sv_prev_btn:hover,
.sv_next_btn:hover {
  border: 1px solid #087ea6 !important;
  color: #ffffff;
  background-color: #087ea6 !important;
  min-width: 140px;
  margin-left: 10px;
}

.sv_complete_btn {
  border: 1px solid #7ea700;
  color: #ffffff;
  background-color: #7ea700;
  min-width: 140px;
  margin-left: 10px;
}
.sv_complete_btn:hover {
  border: 1px solid #7ea700;
  color: #ffffff;
  background-color: #7ea700;
}

/*.alert.alert-danger,
.vue-notifyjs.alert.alert-danger {
  background-color: #f7f7f8;
  color: red;
}*/

h3 {
  font-size: 18px !important;
}
h5 {
  font-size: 14px !important;
}
.sv-q-col-1 {
  font-size: 14px !important;
}
.card-footer {
  text-align: right !important;
}
.sv_main.sv_main .progress {
  width: 100%;
  height: 20px;
}
.progress-bar {
  background-color: #7ea700;
  text-align: left;
  padding-left: 15px;
}
</style>