// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:e34e4416-4bc0-4047-917c-d38633eccf72",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_F4dgdOuKW",
    "aws_user_pools_web_client_id": "4o1mg4kc7rofqkc75gvd77sed0",
    "oauth": {}
};


export default awsmobile;
