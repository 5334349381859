import Vue from 'vue';
import Vuex from 'vuex';
import {
  getField,
  updateField
} from 'vuex-map-fields';
import services from './services';

Vue.use(Vuex);

const state = {
  user: null,
  signedIn: false,
  services
};

const getters = {
  accessToken: state => {
    if (state.user != null) {
      return state.user.signInUserSession.accessToken.jwtToken;
    } else {
      return null;
    }
  },
  getField
};

export default new Vuex.Store({
  state,
  getters,
  mutations: {
    updateField,
    updateSurvey (state, newValue) {
      state.survey = newValue;
    }
  }
});
