<template>
  <li class="breadcrumb-item" :class="{active: active}">
    <slot></slot>
  </li>
</template>
<script>
  export default {
    name: 'breadcrumb-item',
    props: {
      active: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
<style>
.breadcrumb-item.active{
  font-size: 15px;
  color: #5f6368;
}
.breadcrumb-item{
    font-size: 15px;
}
</style>
