class AppetiteService {
  constructor(axios, apiUrl) {
    this.axios = axios;
    // this.baseUrl = 'http://localhost:58810/api/Appetite'
    this.baseUrl = `${apiUrl}Appetite`;
  }

  get(id) {
    let self = this;
    return self.axios.get(`${self.baseUrl}/${id}`);
  }

  getAll() {
    let self = this;
    return self.axios.get(`${self.baseUrl}`);
  }

  add(model) {
    let self = this;
    return self.axios.post(`${self.baseUrl}`, model);
  }

  update(model) {
    let self = this;
    return self.axios.put(`${self.baseUrl}/${model.id}`, model);
  }

  remove(id) {
    let self = this;
    return self.axios.delete(`${self.baseUrl}/${id}`);
  }
}

export default AppetiteService;
