import Vue from 'vue';
import VueRouter from 'vue-router';
import LightBootstrap from './light-bootstrap-main';
// Plugins
import App from './App.vue';

// Vuex: for services, shared components, etc
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';

// highchart
import VueHighcharts from 'vue-highcharts';
import Highcharts from 'highcharts';
import loadDrilldown from 'highcharts/modules/drilldown.js';
import loadHighchartsMore from 'highcharts/highcharts-more.js';
import loadSolidGauge from 'highcharts/modules/solid-gauge.js';

// for surveyjs
import * as Ace from 'ace-builds';
import jsPDF from 'jspdf';


import {
  Loading
} from "element-ui";

// router setup
import routes from './routes/routes';

import Amplify, * as AmplifyModules from 'aws-amplify';
import {
  AmplifyPlugin
} from 'aws-amplify-vue';

//import { Auth } from "aws-amplify";

import aws_exports from './aws-exports';
Amplify.configure(aws_exports);
Vue.use(AmplifyPlugin, AmplifyModules);


loadDrilldown(Highcharts);
loadHighchartsMore(Highcharts);
loadSolidGauge(Highcharts);


// plugin setup
Vue.use(VueAxios, axios);
Vue.use(VueRouter);
Vue.use(LightBootstrap);
Vue.use(require('vue-moment'));
Vue.use(VueHighcharts, {
  Highcharts
});

Vue.use(Loading.directive);

Vue.use(Ace);
Vue.use(jsPDF);



// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'active'
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};


/*axios.interceptors.response.use((response) => {
  console.log(response);
  console.log(response.status);
    console.log (store.state.signedIn);
  if (response.status === 401 || store.state.signedIn === false){
    console.log("Signed Out!!!!!!");
    AmplifyModules.Auth.signOut()
      .then(data =>{
        store.state.signedIn = false;
        store.state.user = null;
        //router.push(`/login`);
      })
      .catch(err => console.log(err));
  }
  return response;
});*/

const UNAUTHORIZED = 401;
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      const {
        status
      } = error.response;
      if (status === UNAUTHORIZED) {
        AmplifyModules.Auth.signOut()
          .then(data => {
            store.state.signedIn = false;
            store.state.user = null;
            router.push("/login");
          })
          .catch(err => console.log(err));
      }
    } else if (!error.response) {
      AmplifyModules.Auth.signOut()
          .then(data => {
            //store.state.signedIn = false;
            //store.state.user = null;
            router.push("/notfound");
          })
          .catch(err => console.log(err));
    }
    return Promise.reject(error);
  }
);


router.beforeResolve((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let user;
    Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then(data => {
      if (data && data.signInUserSession) {
        user = data;

        const {
          authorize
        } = to.meta;
        if (authorize) {
          // check if route is restricted by role
          if (authorize.length && !authorize.some(r => user.signInUserSession.accessToken.payload['cognito:groups'].includes(r))) {
            // role not authorised so redirect to home page
            return next({
              path: '/'
            });
          }
        }
      }
      next();
    }).catch((e) => {
      next({
        path: '/login'
      });
    });
  }
  next();
});


/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store
});
