<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <card v-if="loaded">
          <el-collapse>
            <el-collapse-item>
              <template slot="title">
                <button class="more-btn" type="text">
                  <i class="fa fa-search"></i>
                </button>
                Search for Previous Assessments
              </template>
              <div>
                <div>
                  <div class="row">
                    <div class="col-lg-6">
                      <fg-input>
                        <el-date-picker
                          v-model="searchDate"
                          format="dd-MMM-yyyy"
                          type="daterange"
                          start-placeholder="Start date"
                          end-placeholder="End date"
                          :picker-options="pickerOptions"
                          v-on:input="getAll"
                        ></el-date-picker>
                      </fg-input>
                    </div>
                    <div class="col-lg-6">
                      <fg-input
                        type="search"
                        class="mb-3"
                        placeholder="Search records"
                        v-model="searchQuery"
                        aria-controls="datatables"
                        v-on:input="getAll"
                      />
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <el-table
                      ref="searchAssessmentTable"
                      v-loading="loading"
                      height="290"
                      style="width: 100%;"
                      :data="this.designEffectiveness"
                      highlight-current-row
                      @current-change="handleCurrentChange"
                    >
                      <el-table-column
                        v-for="column in tableColumns"
                        :key="column.label"
                        :min-width="column.minWidth"
                        :prop="column.prop"
                        :label="column.label"
                      ></el-table-column>
                    </el-table>
                  </div>
                </div>
                <div
                  slot="footer"
                  class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
                >
                  <el-select
                    class="select-default mb-3"
                    style="width: 200px"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-default"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                  <div class>
                    <p class="card-category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
                  </div>
                  <l-pagination
                    class="pagination-no-border"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="pagination.total"
                    v-on:input="getAll"
                  ></l-pagination>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <card v-if="loaded" style="height: 50px; background: #F7F7F8; border: 0;">
          <h4
            class="card-title"
          >{{model.date}} Design Effectiveness Assessment Report based on {{model.controlFramework}}</h4>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3" style="min-width:300px !important;">
        <card v-if="loaded" class="header-card">
          <template slot="header">
            <h4 class="card-title">Design Effectiveness Score</h4>
            <!--<i class="fa fa-caret-down score-direction"></i>
            <span style="padding-left:20px">-13</span>-->
            <template v-if="model.score > qualitativeSetting.high ">
              <p class="header-card-score" style="color: green;">{{model.score}}</p>
            </template>
            <template
              v-if="model.score > qualitativeSetting.medium && model.score <= qualitativeSetting.high"
            >
              <p class="header-card-score" style="color: limegreen;">{{model.score}}</p>
            </template>
            <template
              v-if="model.score > qualitativeSetting.low && model.score <= qualitativeSetting.medium"
            >
              <p class="header-card-score" style="color: goldenrod;">{{model.score}}</p>
            </template>
            <template
              v-if="model.score > qualitativeSetting.veryLow && model.score <= qualitativeSetting.low"
            >
              <p class="header-card-score" style="color: darkorange;">{{model.score}}</p>
            </template>
            <template v-if="model.score <= qualitativeSetting.veryLow">
              <p class="header-card-score" style="color: red;">{{model.score}}</p>
            </template>
          </template>
          <template slot="footer">
            <div class="stats">
              <i class="fa fa-history"></i>
              Updated {{model.date}}
            </div>
          </template>
        </card>
      </div>

      <div class="col-md-9">
        <card v-if="loaded" class="header-card">
          <template slot="header">
            <p class="card-category">Last 12 {{model.controlFramework}} Assessment Performance</p>
          </template>
          <highcharts class="chart" :options="lineChart"></highcharts>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <card v-if="loaded">
          <el-collapse v-model="expandedPanels">
            <el-collapse-item name="scoreBreakdown">
              <template slot="title">
                <button class="more-btn" type="text">
                  <i class="fa fa-tachometer-alt"></i>
                </button>
                <h4 class="card-title">Score Breakdown</h4>
              </template>
              <highcharts :options="barChart"></highcharts>
            </el-collapse-item>
          </el-collapse>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <card v-if="loaded">
          <el-collapse>
            <el-collapse-item>
              <template slot="title">
                <button class="more-btn" type="text">
                  <i class="fa fa-balance-scale"></i>
                </button>
                <h4 class="card-title">Compare with set Targets</h4>
              </template>
              <highcharts :options="barChartCompare"></highcharts>
            </el-collapse-item>
          </el-collapse>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox
} from "src/components/index";

import More from "highcharts/highcharts-more";
import Highcharts, { dateFormat } from "highcharts";
import {
  Table,
  TableColumn,
  Select,
  Option,
  Collapse,
  CollapseItem,
  DatePicker,
  TimeSelect
} from "element-ui";
import {
  Pagination as LPagination,
  Progress as LProgress
} from "src/components/index";
import $ from "jquery";
import { std, mean } from "mathjs";

More(Highcharts);
var dEControlAssessmentSurveys = [];
var controlFrameworkCategories = [];
var dETargets = [];
var dEControlAssessmentSurveysCurrent = [];
var designEffectivenessLastTwelve = [];

export default {
  components: {
    Checkbox,
    Card,
    LTable,
    ChartCard,
    StatsCard,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    LProgress,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect
  },

  computed: {
    pagedData() {
      let self = this;
      return self.designEffectiveness.slice(self.from, self.to);
    },
    to() {
      let self = this;
      let highBound = self.from + self.pagination.perPage;
      if (self.total < highBound) {
        highBound = self.total;
      }
      return highBound;
    },
    from() {
      let self = this;
      return self.pagination.perPage * (self.pagination.currentPage - 1);
    },
    total() {
      let self = this;
      return self.pagination.total;
    }
  },

  data() {
    return {
      model: {
        id: 0,
        controlFrameworkId: 0,
        description: "",
        score: "",
        controlFramework: "",
        date: ""
      },
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: "",
      searchDate: "",
      searchDateString: "",
      tableColumns: [
        {
          prop: "date",
          label: "Date",
          sortable: true,
          minWidth: 150
        },
        {
          prop: "controlFramework",
          label: "Control Framework",
          minWidth: 150
        },
        {
          prop: "score",
          label: "Score",
          minWidth: 60
        }
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "Last week",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Last month",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            }
          },
          {
            text: "Last 3 months",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            }
          }
        ]
      },
      loading: true,
      designEffectiveness: [],
      currentRow: null,
      expandedPanels: [],
      qualitativeSetting: {
        id: 0,
        name: "",
        veryHigh: 0,
        high: 0,
        medium: 0,
        low: 0,
        veryLow: 0
      },

      editTooltip: "Edit Task",
      deleteTooltip: "Remove",
      lineChart: {
        chart: {
          //type: "spline",
          type: "line",
          height: 245
        },
        title: {
          text: ""
        },
        xAxis: {
          max: 12,
          min: 0
        },
        yAxis: {
          title: {
            text: "Score"
          },
          max: 100,
          min: 0,
          plotLines: [
            {
              color: "#527055",
              width: 2,
              value: 0,
              dashStyle: "longdashdot"
            },
            {
              color: "#ba3f50",
              width: 2,
              value: 0,
              dashStyle: "longdashdot"
            },
            {
              color: "#ba3f50",
              width: 2,
              value: 0,
              dashStyle: "longdashdot"
            }
          ]
        },
        tooltip: {
          crosshairs: true,
          shared: true
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          spline: {
            marker: {
              radius: 4,
              lineColor: "#666666",
              lineWidth: 1
            }
          },
          series: {
            tooltip: {
              headerFormat: "",
              pointFormat:
                "<span style='color:{point.color}'>●</span> {series.name}: <b>{point.y}, </b>Date: <b>{point.date}</b>"
            },
            cursor: "pointer",
            point: {
              events: {
                click: ({ point }) => {
                  this.get(point.id);
                }
              }
            }
          }
        },
        series: [
          {
            name: "Design Effectiveness Score",
            data: []
            //color: "grey",
            //pointPadding: 0.1
          }
        ]
      },

      barChart: {
        chart: {
          type: "bar"
        },
        title: {
          text: ""
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          max: 100,
          title: {
            text: "Score"
          }
        },
        tooltip: {
          crosshairs: true,
          shared: true
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            tooltip: {
              pointFormat:
                "<span style='color:{point.color}'>●</span> {series.name}: <b>{point.y}, </b>Weighting: <b>{point.weighting}</b>"
            },
            cursor: "pointer",
            point: {
              events: {
                click: ({ point }) => {
                  this.pointClick(point.id);
                }
              }
            }
          },
          bar: {
            zones: [
              {
                value: 40, // Values up to 40 (not including) ...
                color: "red" // ... have the color blue.
              },
              {
                value: 60,
                color: "darkorange"
              },
              {
                value: 80,
                color: "goldenrod"
              },
              {
                value: 90,
                color: "limegreen"
              },
              {
                color: "green" // Values from 10 (including) and up have the color red
              }
            ]
          }
        },
        series: [
          {
            name: "Control Effectiveness Score",
            data: []
          }
          /*{
            name: "Target",
            data: []
          }*/
        ]
      },

      barChartCompare: {
        chart: {
          type: "bar"
        },
        title: {
          text: ""
        },
        xAxis: {
          categories: []
        },
        yAxis: {
          max: 100,
          title: {
            text: "Score"
          }
        },
        tooltip: {
          crosshairs: true,
          shared: true
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            grouping: false,
            shadow: false,
            opacity: 0.8
          }
        },
        series: [
          {
            name: "Target",
            data: [],
            color: "grey",
            stack: "target",
            pointPadding: 0.1
          },
          {
            name: "Most Recent Score",
            data: [],
            color: "#527fff",
            stack: "recent",
            pointPadding: 0.2
          },
          {
            name: "Selected Assessment Score",
            data: [],
            color: "indigo",
            stack: "selected",
            pointPadding: 0.3
          }
        ]
      },

      Highcharts,
      loaded: false
    };
  },

  created() {
    let self = this;
    self.getAll();
     self.loaded = true;
  },

  methods: {
    getAll() {
      let self = this;
      if (self.searchDate !== null) {
        if (self.searchDate.length !== 0) {
          let startDate = new Date(self.searchDate[0]);
          let endDate = new Date(self.searchDate[1]);
          self.searchDateString =
            startDate.getFullYear() +
            "-" +
            (startDate.getMonth() + 1) +
            "-" +
            startDate.getDate() +
            "," +
            endDate.getFullYear() +
            "-" +
            (endDate.getMonth() + 1) +
            "-" +
            endDate.getDate();
        }
      } else {
        self.searchDateString = "";
      }

      self.$store.state.services.dEControlAssessmentService
        .getAll({
          headers: {
            Pagination: `${self.pagination.currentPage},${self.pagination.perPage}`,
            organisationId: self.$route.params.organisationId
          },
          params: {
            Filter: `${self.searchQuery}`,
            DateFilter: `${self.searchDateString}`,
            DesignEffectivenessDashboard: "true"
          }
        })
        .then(r => {
          self.designEffectiveness = r.data;
          self.headerValues = JSON.parse(r.headers.pagination);
          self.pagination.total = self.headerValues.TotalItems;
          self.pagination.currentPage = self.headerValues.CurrentPage;
          self.pagination.perPage = self.headerValues.ItemsPerPage;
          if (self.$route.params.controlassessmentId > 0) {
            self.get(self.$route.params.controlassessmentId);
            self.expandedPanels.push("scoreBreakdown");
          } else if (r.data.length > 0) {
            self.setCurrent(self.designEffectiveness[0]);
            //self.get(r.data[0].id);
          }
          self.getQualitativeSetting();
          self.loading = false;
        })
        .catch(err => console.log(err));
    },
    get(id) {
      let self = this;
      self.$store.state.services.dEControlAssessmentService
        .get(id)
        .then(r => {
          self.model.id = r.data.id;
          self.model.controlFramework = r.data.controlFramework;
          self.model.description = r.data.description;
          self.model.controlFrameworkId = r.data.controlFrameworkId;
          self.model.score = r.data.score;
          self.model.date = r.data.date;
          self.getDEControlAssessmentSurveys(self.model.id, false);

          self.$store.state.services.dEControlAssessmentService
            .getAll({
              headers:{
                organisationId: self.$route.params.organisationId
              },
              params: {
                ControlFrameworkId: self.model.controlFrameworkId.toString(),
                LastTwelve: "true",
                ForDashboard: "true"
              }
            })
            .then(r => {
              designEffectivenessLastTwelve = [];
              self.getDEControlAssessmentSurveys(
                r.data[r.data.length - 1].id,
                true
              );
              var controlLimitData = [];
              for (var i = 0; i < r.data.length; i++) {
                designEffectivenessLastTwelve.push({
                  name: r.data[i].name,
                  y: r.data[i].score,
                  id: r.data[i].id,
                  date: r.data[i].date
                });
                controlLimitData.push(r.data[i].score);
              }
              self.lineChart.series[0].data = designEffectivenessLastTwelve;
              self.lineChart.yAxis.plotLines[0].value = Math.round(
                mean(controlLimitData)
              );
              self.lineChart.yAxis.plotLines[1].value = Math.round(
                mean(controlLimitData) + 2 * std(controlLimitData)
              );
              self.lineChart.yAxis.plotLines[2].value = Math.round(
                mean(controlLimitData) - 2 * std(controlLimitData)
              );
            });
        })
        .catch(err => console.log(err));
    },
    getDEControlAssessmentSurveys(value, forCurrent) {
      let self = this;
      self.$store.state.services.dEControlAssessmentSurveyService
        .getAll({
          params: {
            DEControlAssessmentId: value
          }
        })
        .then(r => {
          if (forCurrent) {
            dEControlAssessmentSurveysCurrent = [];
            dETargets = [];
            for (var i = 0; i < r.data.length; i++) {
              dEControlAssessmentSurveysCurrent.push(r.data[i].score);
              dETargets.push(r.data[i].targetScore);
            }
            self.barChartCompare.series[1].data = dEControlAssessmentSurveysCurrent;
            self.barChartCompare.series[0].data = dETargets;
          } else {
            dEControlAssessmentSurveys = [];
            controlFrameworkCategories = [];
            for (var i = 0; i < r.data.length; i++) {
              dEControlAssessmentSurveys.push({
                name: r.data[i].name,
                y: r.data[i].score,
                id: r.data[i].id,
                weighting: r.data[i].weighting
              });
              controlFrameworkCategories.push(r.data[i].name);
            }
            self.barChart.series[0].data = dEControlAssessmentSurveys;
            self.barChart.xAxis.categories = controlFrameworkCategories;
            self.barChartCompare.series[2].data = dEControlAssessmentSurveys;
            self.barChartCompare.xAxis.categories = controlFrameworkCategories;
          }
        })
        .catch(err => console.log(err));
    },
    getQualitativeSetting() {
      let self = this;
      self.$store.state.services.appetiteService
        //3 = Control Assessment
        .get(3)
        .then(r => {
          self.qualitativeSetting.id = r.data.id;
          self.qualitativeSetting.name = r.data.name;
          self.qualitativeSetting.veryHigh = r.data.veryHigh;
          self.qualitativeSetting.high = r.data.high;
          self.qualitativeSetting.medium = r.data.medium;
          self.qualitativeSetting.low = r.data.low;
          self.qualitativeSetting.veryLow = r.data.veryLow;
        })
        .catch(err => console.log(err));
    },
    pointClick(id) {
      let self = this;
      self.$router.push(
        `/controlAssessmentDashboards/${self.$route.params.organisationId}/designeffectivenessdashboard/${self.model.id}/${id}`
      );
    },
    setCurrent(row) {
      let self = this;
      self.$refs.searchAssessmentTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      let self = this;
      if (val !== null) {
        self.get(val.id);
        self.currentRow = val;
      }
    }
  }
};
</script>
<style>
.header-card {
  height: 300px !important;
}
.header-card-score {
  text-align: center;
  font-size: 100px;
  margin-top: 20px;
  margin-bottom: 0px;
  /*font-size:7vw;*/
}
.score-direction {
  font-size: 40px;
  padding-top: 50px;
  padding-left: 30px;
  color: red;
}
.el-collapse-item__header {
  border-bottom: 0 !important;
}
.el-collapse {
  border-top: 0 !important;
  border-bottom: 0 !important;
}

button:focus {
  outline: 0;
  outline: 0;
}
</style>
