import axios from 'axios';
import AppetiteService from '../../services/AppetiteService';
import IndustryService from '../../services/IndustryService';
import CountryService from '../../services/CountryService';
import OrganisationService from '../../services/OrganisationService';
import ThreatCommunityService from '../../services/ThreatCommunityService';
import LossService from '../../services/LossService';
import ControlFrameworkService from '../../services/ControlFrameworkService';
import ControlFrameworkCategoryService from '../../services/ControlFrameworkCategoryService';
import SurveyService from '../../services/SurveyService';
import DEControlAssessmentService from '../../services/DEControlAssessmentService';
import DEControlAssessmentSurveyService from '../../services/DEControlAssessmentSurveyService';
import UserService from '../../services/UserService';
import UserCognitoService from '../../services/UserCognitoService';
import OEMetricService from '../../services/OEMetricService';
import OEControlAssessmentService from '../../services/OEControlAssessmentService';
import OEControlAssessmentCategoryService from '../../services/OEControlAssessmentCategoryService';
import store from '../store';
import VueRouter from 'vue-router';

//let apiUrl = 'https://localhost:58811/api/';
//let apiUrl = 'http://api.aspral.com/api/';
//let apiUrl = 'https://56k3ulyj9f.execute-api.eu-west-2.amazonaws.com/dev/api/';
let apiUrl = 'https://api.theiarisksolutions.co.uk/api/';

let cognitoAPIUrl = 'https://gjlwza6g10.execute-api.eu-west-2.amazonaws.com/dev';

//const apiUrl = process.env.VUE_APP_BASEURL;

//alert(process.env.VUE_APP_BASEURL);


// Axios Configuration
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'https://app.theiarisksolutions.co.uk';
axios.defaults.withCredentials = true;
axios.defaults.crossorigin = true
axios.defaults.headers.common['Access-Control-Allow-Credentials'] = 'true';


const authentication = axios.interceptors.request.use((config) => {
  config.headers.Authorization = 'Bearer ' + store.getters.accessToken;
  return config;
});


export default {
  appetiteService: new AppetiteService(axios, apiUrl),
  industryService: new IndustryService(axios, apiUrl),
  countryService: new CountryService(axios, apiUrl),
  organisationService: new OrganisationService(axios, apiUrl),
  threatCommunityService: new ThreatCommunityService(axios, apiUrl),
  lossService: new LossService(axios, apiUrl),
  controlFrameworkService: new ControlFrameworkService(axios, apiUrl),
  controlFrameworkCategoryService: new ControlFrameworkCategoryService(axios, apiUrl),
  surveyService: new SurveyService(axios, apiUrl),
  dEControlAssessmentService: new DEControlAssessmentService(axios, apiUrl),
  dEControlAssessmentSurveyService: new DEControlAssessmentSurveyService(axios, apiUrl),
  userService: new UserService(axios, apiUrl),
  userCognitoService: new UserCognitoService(axios, cognitoAPIUrl),
  oEMetricService: new OEMetricService(axios, apiUrl),
  oEControlAssessmentService: new OEControlAssessmentService(axios, apiUrl),
  oEControlAssessmentCategoryService: new OEControlAssessmentCategoryService(axios, apiUrl)
};
