<template>
  <div class="row">
    <div class="col-md-12">
      <div class="col-12">
        <card v-if="loaded">
          <div>
            <div style="margin-bottom: 10px" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <fg-input
                type="search"
                class="mb-3"
                style="width: 200px"
                placeholder="Search records"
                v-model="searchQuery"
                aria-controls="datatables"
                v-on:input="getAll"
              />
              <div>
              <span class="data-tile-card-text">Select Organisation</span>
              </div>
            </div>

            <ul class="data-tile row">
              <li
                class="col-sm-6 col-lg-4"
                style="min-width: 300px;"
                v-for="organisation in organisations"
                :key="organisation.id"
                :value="organisation.id"
              >
                <div class="image-flip">
                  <div class="text-center">
                    <div class="mainflip flip-0">
                      <div class="frontside data-tile-card">
                        <div class="card-body" style="margin:65px">
                          <!--<div class="data-tile-icon fa fa-building organisation-icon"></div>

                          <br />
                          <br />-->
                          <span style="font-size:25px; color:grey; font-weight:bold;" class="data-tile-card-text">{{organisation.name}}</span>
                        </div>
                      </div>
                      <div class="backside data-tile-card">
                        <div class="card-body">
                          <button class="btn btn-link data-tile-btn-text-left" style="width:100%">
                            <i class="fa fa-percent button-icon"></i> Theia Control Effectiveness
                          </button>
                          <button
                            @click="$router.push(`/controlAssessmentDashboards/${organisation.id}/designeffectivenessdashboard/0`)"
                            class="btn btn-link data-tile-btn-text-left"
                            style="width:100%"
                          >
                            <i class="fa fa-pencil-ruler button-icon"></i> Design Effectiveness
                          </button>
                          <br />
                          <button class="btn btn-link data-tile-btn-text-left" style="width:100%">
                            <i class="fa fa-bullhorn button-icon"></i> Extent of Deployment
                          </button>
                          <br />
                          <button class="btn btn-link data-tile-btn-text-left" style="width:100%">
                            <i class="fa fa-chart-line button-icon"></i> Operational Effectiveness
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <el-select
              class="select-default mb-3"
              style="width: 200px"
              v-model="pagination.perPage"
              placeholder="Per page"
            >
              <el-option
                class="select-default"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
            <div class>
              <p class="card-category">Showing {{from + 1}} to {{to}} of {{total}} entries</p>
            </div>
            <l-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="pagination.total"
              v-on:input="getAll"
            ></l-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { Pagination as LPagination } from "src/components/index";
import $ from "jquery";

export default {
  components: {
    LPagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  computed: {
    pagedData() {
      let self = this;
      return self.organisations.slice(self.from, self.to);
    },
    to() {
      let self = this;
      let highBound = self.from + self.pagination.perPage;
      if (self.total < highBound) {
        highBound = self.total;
      }
      return highBound;
    },
    from() {
      let self = this;
      return self.pagination.perPage * (self.pagination.currentPage - 1);
    },
    total() {
      let self = this;
      return self.pagination.total;
    }
  },
  data() {
    return {
      pagination: {
        perPage: 9,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: "",
      organisations: [],
      loaded: false
    };
  },

  created() {
    let self = this;
    self.getAll();
  },

  methods: {
    getAll() {
      let self = this;
      self.$store.state.services.organisationService
        .getAll({
          headers: {
            Pagination: `${self.pagination.currentPage},${self.pagination.perPage}`
          },
          params: {
            Filter: `${self.searchQuery}`,
            ActiveOrganisations: true
          }
        })
        .then(r => {
          self.organisations = r.data;
          self.headerValues = JSON.parse(r.headers.pagination);
          self.pagination.total = self.headerValues.TotalItems;
          self.pagination.currentPage = self.headerValues.CurrentPage;
          self.pagination.perPage = self.headerValues.ItemsPerPage;
          self.loaded = true;
        })
        .catch(err => console.log(err));
    }
  }
};
</script>



<style>
.data-tile-btn-text-left:hover {
  background-color: #d9e7fc !important;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.ul.ccc li {
  display: inline-block;
}
</style>


