<template>
  <auth-layout pageClass="login-page">
    <div class="row d-flex justify-content-center align-items-center">
      <!--<div class="col-lg-4 col-md-6 col-sm-8">
          <fade-render-transition>
            <card>
              <div slot="header">
                <h3 class="card-title text-center">Login</h3>
              </div>
              <div>
                <fg-input label="Email address"
                          name="username"
                          placeholder="Enter email"
                          type="email"
                          v-model="model.username">

                </fg-input>
                <fg-input label="passsword"
                          type="password"
                          placeholder="Password"
                          v-model="model.password">
                </fg-input>
              </div>
              <div class="text-center">
                <button @click="signIn" class="btn btn-fill btn-info btn-round btn-wd ">Login</button>
                <br>
                <div class="forgot">
                  <router-link to="/register" class="card-category">
                    Forgot your password?
                  </router-link>
                </div>
              </div>
            </card>
          </fade-render-transition>
      </div>-->
      <fade-render-transition>
        <amplify-authenticator></amplify-authenticator>
        <!--<amplify-sign-up></amplify-sign-up>
              <amplify-authenticator></amplify-authenticator>
              <amplify-confirm-sign-in></amplify-confirm-sign-in>
              <amplify-forgot-password></amplify-forgot-password>
              <amplify-set-mfa></amplify-set-mfa>
        <amplify-sign-out></amplify-sign-out>-->
      </fade-render-transition>
    </div>
  </auth-layout>
</template>
<script>
import { FadeRenderTransition } from "src/components/index";
import AuthLayout from "./AuthLayout.vue";
//import { Auth } from "aws-amplify";
import { AmplifyEventBus } from "aws-amplify-vue";

export default {
  components: {
    FadeRenderTransition,
    AuthLayout
  }

  /*created() {
    let self = this;
    Auth.currentAuthenticatedUser()
      .then(user => {
        self.$router.push({ path: "/" });
      })
      .catch(() => {
        this.$store.state.signedIn = false;
        this.$store.state.user = null;
      });
  } */
  
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
:root {
  --button-background-color: #087ea6;
  --button-click: #087ea6;
}
</style>
