<template>
  <card>
    <div slot="header">
      <h4 class="title">Edit Profile</h4>
    </div>
    <div>
      <form>
        <div class="row">
          <div class="col-md-5">
            <fg-input
              type="text"
              label="Company"
              :disabled="true"
              placeholder="Paper dashboard"
              v-model="user.company"
            ></fg-input>
          </div>
          <div class="col-md-3">
            <fg-input type="text" label="Username" placeholder="Username" v-model="user.username"></fg-input>
          </div>
          <div class="col-md-4">
            <fg-input type="email" label="Username" placeholder="Email" v-model="user.email"></fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <fg-input
              type="text"
              label="First Name"
              placeholder="First Name"
              v-model="user.firstName"
            ></fg-input>
          </div>
          <div class="col-md-6">
            <fg-input type="text" label="Last Name" placeholder="Last Name" v-model="user.lastName"></fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <fg-input type="text" label="Address" placeholder="Home Address" v-model="user.address"></fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <fg-input type="text" label="City" placeholder="City" v-model="user.city"></fg-input>
          </div>
          <div class="col-md-4">
            <fg-input type="text" label="Country" placeholder="Country" v-model="user.country"></fg-input>
          </div>
          <div class="col-md-4">
            <fg-input label="Postal Code" placeholder="ZIP Code" v-model="user.postalCode"></fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <fg-input label="About Me">
              <el-input type="textarea" autosize placeholder="ZIP Code" v-model="user.aboutMe"></el-input>
            </fg-input>
          </div>
        </div>
        <div class="text-center">
          <button
            type="submit"
            class="btn btn-info btn-fill btn-wd"
            @click.prevent="updateProfile"
          >Update Profile</button>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </card>
</template>
<script>
export default {
  data() {
    return {
      user: {
        company: "Paper Dashboard",
        username: "michael23",
        email: "",
        lastName: "Faker",
        address: "Melbourne, Australia",
        city: "melbourne",
        postalCode: "",
        aboutMe: `Oh so, your weak rhyme. You doubt I'll bother, reading into it.I'll probably won't, left to my own devicesBut that's the difference in our opinions.`
      }
    };
  },
  methods: {
    updateProfile() {
      alert("Your data: " + JSON.stringify(this.user));
    }
  }
};
</script>
<style>
</style>
