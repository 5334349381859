<template>
  <div>
    <div class="row">
      <div class="col-md-3">
        <card class="header-card">
          <template slot="header">
            <h4 class="card-title">Theia CE Score Test (KN)</h4>
            <!--<i class="fa fa-caret-down score-direction"></i>
            <span style="padding-left: 20px">-13</span>-->
            <p class="header-card-score" style="color: green">72</p>
          </template>
          <template slot="footer">
            <div class="stats">
              <i class="fa fa-history"></i> Updated 14-Mar-2021
            </div>
          </template>
        </card>
      </div>

      <div class="col-md-9">
        <card class="header-card">
          <template slot="header">
            <p class="card-category">12 Months Performance</p>
          </template>
          <highcharts class="chart" :options="lineChart"></highcharts>
        </card>
      </div>
    </div>

    <!--
<div class="row">
        <div class="col-xl-3 col-md-6">
          <stats-card title="150GB" subTitle="Numbers">
            <div slot="header" class="icon-warning">
              <i class="nc-icon nc-chart text-warning"></i>
            </div>
            <template slot="footer">
              <i class="fa fa-refresh"></i>New Op Effectiveness Assessment
            </template>
          </stats-card>
        </div>

        <div class="col-xl-3 col-md-6">
          <stats-card title="$ 1,345" subTitle="Revenue">
            <div slot="header" class="icon-success">
              <i class="nc-icon nc-light-3 text-success"></i>
            </div>
            <template slot="footer">
              <i class="fa fa-calendar-o"></i>Last day
            </template>
          </stats-card>
        </div>

        <div class="col-xl-3 col-md-6">
          <stats-card title="23" subTitle="Errors">
            <div slot="header" class="icon-danger">
              <i class="nc-icon nc-vector text-danger"></i>
            </div>
            <template slot="footer">
              <i class="fa fa-clock-o"></i>Last day
            </template>
          </stats-card>
        </div>

        <div class="col-xl-3 col-md-6">
          <stats-card title="+45K" subTitle="Followers">
            <div slot="header" class="icon-info">
              <i class="nc-icon nc-favourite-28 text-primary"></i>
            </div>
            <template slot="footer">
              <i class="fa fa-refresh"></i>Updated now
            </template>
          </stats-card>
        </div>

      </div>
-->

    <div class="row">
      <div class="col-sm-6 col-lg-3">
        <card>
          <div style="text-align: center">Access Control</div>
          <highcharts
            :highcharts="Highcharts"
            :options="guageChart"
            ref="chart"
          ></highcharts>
        </card>
      </div>
      <div class="col-sm-6 col-lg-3">
        <card>
          <div style="text-align: center">Business Continuity Management</div>
          <highcharts
            :highcharts="Highcharts"
            :options="guageChart"
            ref="chart"
          ></highcharts>
        </card>
      </div>
      <div class="col-sm-6 col-lg-3">
        <card>
          <div style="text-align: center">Physical Security</div>
          <highcharts
            :highcharts="Highcharts"
            :options="guageChart"
            ref="chart"
          ></highcharts>
        </card>
      </div>
      <div class="col-sm-6 col-lg-3">
        <card>
          <div style="text-align: center">Data Leak Prevention</div>
          <highcharts
            :highcharts="Highcharts"
            :options="guageChart"
            ref="chart"
          ></highcharts>
        </card>
      </div>
      <div class="col-sm-6 col-lg-3">
        <card>
          <div style="text-align: center">Patching</div>
          <highcharts
            :highcharts="Highcharts"
            :options="guageChart"
            ref="chart"
          ></highcharts>
        </card>
      </div>
      <div class="col-sm-6 col-lg-3">
        <card>
          <div style="text-align: center">Security Consulting</div>
          <highcharts
            :highcharts="Highcharts"
            :options="guageChart"
            ref="chart"
          ></highcharts>
        </card>
      </div>
      <div class="col-sm-6 col-lg-3">
        <card>
          <div style="text-align: center">Governance</div>
          <highcharts
            :highcharts="Highcharts"
            :options="guageChart"
            ref="chart"
          ></highcharts>
        </card>
      </div>

      <div class="col-sm-6 col-lg-3">
        <card>
          <div style="text-align: center">Intrusion Detection</div>
          <highcharts
            :highcharts="Highcharts"
            :options="guageChart"
            ref="chart"
          ></highcharts>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ChartCard,
  StatsCard,
  Card,
  Table as LTable,
  Checkbox,
} from "src/components/index";

import More from "highcharts/highcharts-more";
import Highcharts from "highcharts";
More(Highcharts);

export default {
  components: {
    Checkbox,
    Card,
    LTable,
    ChartCard,
    StatsCard,
  },
  data() {
    return {
      score: 70,
      editTooltip: "Edit Task",
      deleteTooltip: "Remove",
      lineChart: {
        chart: {
          type: "line",
          height: 245,
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
          ],
        },
        yAxis: {
          title: {
            text: "Score",
          },
        },
        tooltip: {
          crosshairs: true,
          shared: true,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          spline: {
            marker: {
              radius: 4,
              lineColor: "#666666",
              lineWidth: 1,
            },
          },
        },
        series: [
          {
            name: "Control Effectiveness Score",
            data: [50, 76, 38, 25, 68, 78, 80, 74, 68, 34, 85, 72],
          },
        ],
      },
      guageChart: {
        credits: {
          enabled: false,
        },
        chart: {
          type: "solidgauge",
          plotBackgroundColor: null,
          plotBackgroundImage: null,
          plotBorderWidth: 0,
          plotShadow: false,
          height: 200,
        },

        title: {
          text: null,
        },

        pane: {
          //center: ["50%", "85%"],
          size: "90%",
          startAngle: -130,
          endAngle: 130,
          background: {
            backgroundColor:
              Highcharts.defaultOptions.legend.backgroundColor || "#EEE",
            innerRadius: "60%",
            outerRadius: "100%",
            borderWidth: 0,
            shape: "arc",
          },
        },

        exporting: {
          enabled: false,
        },

        tooltip: {
          enabled: false,
        },

        // the value axis
        yAxis: {
          stops: [
            [0.1, "#DF5353"], // red
            [0.5, "#DDDF0D"], // yellow
            [0.9, "#55BF3B"], // green
          ],
          labels: {
            enabled: false,
          },
          min: 0,
          max: 100,
          gridLineColor: "transparent",
          lineColor: "transparent",
          minorTickLength: 0,
          tickPositions: [70],
          tickColor: "grey",
          tickPosition: "inside",
          tickLength: 32,
          tickWidth: 5,
          zIndex: 100, //added
        },
        plotOptions: {
          solidgauge: {
            dataLabels: {
              y: 5,
              borderWidth: 0,
              useHTML: true,
              enabled: true,
              zIndex: 1000, //added
            },
          },
        },

        series: [
          {
            name: "CE Score",
            data: [70]
            //dataLabels: {
            //format: '<span style="font-size:25px">${y} M</span><br/>',
            //},
          }
        ]
      },
      Highcharts
    };
  },
};
</script>
<style>
.header-card {
  height: 350px !important;
}
.header-card-score {
  text-align: center;
  font-size: 100px;
  margin-top: -70px;
}
.score-direction {
  font-size: 40px;
  padding-top: 0px;
  padding-left: 30px;
  color: red;
}
</style>
